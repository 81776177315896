import Env from '../env';
import {api} from './axiosConfig';

interface BooksApi {
  getBooks: () => Promise<APIResponse>;
  getBooksFreeze: () => Promise<APIResponse>;
}

const URL_BOOKS = '/books';

const BooksAPI: BooksApi = {
  getBooks: async () => {
    const response = await api.request({
      url: `${URL_BOOKS}/`,
      method: 'GET',
    });
    return response.data;
  },

  getBooksFreeze: async () => {
    const response = await api.request({
      baseURL: Env.PROD_URL,
      url: `${URL_BOOKS}/freeze/list`,
      method: 'GET',
    });
    return response.data;
  },
};

export default BooksAPI;
