import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import '../css/pages/RunList.css';
import returnLogo from '../assets/returnLogo.png';
import {NotificationManager} from 'react-notifications';
import {withProtected} from '../context/user';
import RunRecap from '../components/RunRecap';
import SquareButton from '../components/SquareButton';
import Modal from '../components/Modal';
import ToolsAPI from '../api/ToolsAPI';

function RunList() {
  const [runs, setRuns] = useState<Run[]>([]);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const showModal = () => {
    setShow(true);
  };
  const hideModal = () => {
    setShow(false);
  };

  const fetchRuns = async () => {
    try {
      const res: any = await ToolsAPI.fetchRuns();
      setRuns(res.runs);
    } catch (e) {
      NotificationManager.error('Could not fetch runs', 'Error', 1500);
      console.error(`Could not fetch status: ${e}`);
    }
  };

  const purgeLogs = async () => {
    try {
      const res: any = await ToolsAPI.purgeLogs();
      const msg = res.message;
      if (msg === 'ok') {
        fetchRuns();
        hideModal();
        NotificationManager.success('Purged logs', 'Success', 1500);
      } else if (msg === 'invalid') {
        console.error('invalid state');
        NotificationManager.error('Invalid state (must be idle)', 'Could not purge logs', 1500);
      } else {
        NotificationManager.error('Something went wrong', 'Could not purge logs', 1500);
        console.error(`Could not purge logs : unknown error`);
      }
    } catch (e) {
      NotificationManager.error('Something went wrong', 'Could not purge logs', 1500);
      console.error(`Could not purge logs: ${e}`);
    }
  };

  useEffect(() => {
    fetchRuns();
  }, []);

  return (
    <>
      <header>
        <img
          src={returnLogo}
          onClick={() => navigate('/MediaFactory')}
          className="link-logo"
          alt="return_logo"
          style={{height: '50px', width: '50px', marginTop: '50px'}}
        />
      </header>
      <div className="RunListContainer">
        <Modal handleClose={hideModal} show={show}>
          <div
            style={{
              backgroundColor: '#252931',
              minHeight: '20vh',
              paddingTop: '5px',
              flexDirection: 'column',
            }}>
            <h2 style={{color: 'red'}}>Are you sure you want to purge logs ?</h2>
            <div>
              <div style={{maxWidth: '100px', margin: '30px auto'}}>
                <SquareButton useButton={purgeLogs} disabled={false} text={'YES'} isBig={true} />
              </div>
            </div>
          </div>
        </Modal>
        <h1>RUN RECAP LIST ⚙📝</h1>
        {runs.length > 0 ? (
          <div
            style={{
              overflowY: 'scroll',
              maxHeight: '60vh',
              borderBottom: '1px solid lightgray',
              marginBottom: '20px',
            }}>
            <ul>
              {runs.reverse().map((run) => (
                <li className="RunListElt">
                  <RunRecap
                    message={`Run № ${run.id ?? '???'}`}
                    error={run.err ?? ''}
                    logs={run.logs}
                  />
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <h3 style={{color: 'salmon', marginBottom: '50px'}}>No past runs records found</h3>
        )}
        <div>
          <SquareButton
            useButton={showModal}
            isBig={true}
            disabled={runs.length < 1}
            text={'PURGE'}
          />
        </div>
      </div>
    </>
  );
}

export default withProtected(RunList);
