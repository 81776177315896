import React, {useCallback, useEffect, useRef, useState} from 'react';
import '../css/pages/MediaFactory.css';
import 'react-notifications/lib/notifications.css';
import {NotificationManager} from 'react-notifications';
import {withProtected} from '../context/user';
import {Link, useNavigate} from 'react-router-dom';
import SquareButton from '../components/SquareButton';
import Modal from '../components/Modal';
import Logs from '../components/Logs';
import ColoredDot from '../components/ColoredDot';
import RunRecap from '../components/RunRecap';
import CoolArrows from '../components/CoolArrows';
import homeLogo from '../assets/homeLogo.png';
import ToolsAPI from '../api/ToolsAPI';
import DbBooks from '../components/DbBooks';
import BooksAPI from '../api/BooksAPI';

function MediaFactory({dbBooks, getDbBooks}: DbBooksProps) {
  const usePrevious = <T extends unknown>(value: T): T | undefined => {
    const ref = useRef<T>();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };
  const notifTimeout = 1500;
  const logEndSequence = '[SOUNDFACTORY END]';
  const [notified, setNotified] = useState(false);
  const [toolsStatus, setToolsStatus] = useState('Idle');
  const prevStatus = usePrevious(toolsStatus);
  const [books, setBooks] = useState<Book[]>([]);
  const [lastLogs, setLastLogs] = useState<string[]>([]);
  const [lastErr, setErr] = useState('');
  const [listFreeze, setListFreeze] = useState<ToolsBook[]>([]);
  const [runCount, setRunCount] = useState<number>(0);
  const [selectedCount, setSelectedCount] = useState<number>(0);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [booksSelection, setBooksSelection] = useState<bookSelection[]>([]);
  const [factoryLaunched, setFactoryLaunched] = useState(false);
  const [factoryScope, setFactoryScope] = useState<string>('');
  const navigate = useNavigate();

  const showConfirmationModal = () => {
    setShowConfirmation(true);
  };
  const hideConformationModal = () => {
    setShowConfirmation(false);
  };

  const selectionCount = useCallback(() => {
    let count = 0;
    for (let i = 0; i < booksSelection.length; i++) {
      if (booksSelection[i].selected) {
        count++;
      }
    }
    return count;
  }, [booksSelection]);

  const getSelectionAsString = () => {
    let str = '';
    for (let i = 0; i < booksSelection.length; i++) {
      if (booksSelection[i].selected) {
        if (str !== '') str += ',';
        str += booksSelection[i].id;
      }
    }
    return str;
  };

  const getAllBooksAsString = () => {
    let str = '';
    for (let i = 0; i < listFreeze.length; i++) {
      if (str !== '') str += ',';
      str += listFreeze[i].name;
    }
    return str;
  };

  const runRecapProps = {
    message: `Last run : № ${runCount ?? '???'}`,
    logs: lastLogs,
    error: lastErr,
  };

  // Reset cache
  const onResetCache = async () => {
    try {
      const res = await ToolsAPI.resetCache();
    } catch (e) {
      NotificationManager.error('Could not reset cache music files', 'API Error', notifTimeout);
      console.error(`Could not fetch reset cache: ${e}`);
    }
  };

  const fetchStatus = useCallback(async () => {
    try {
      const res = await ToolsAPI.getStatus();
      if (factoryLaunched && res.status === 'Idle') {
        setBooks([]);
        getDbBooks();
        setSelectedCount(0);
        setBooksSelection([]);
        setFactoryLaunched(false);
        setFactoryScope('');
        setLastLogs([]);
      }
      setToolsStatus(res.status);
    } catch (e) {
      NotificationManager.error('Could not fetch status', 'API Error', notifTimeout);
      console.error(`Could not fetch status: ${e}`);
    }
  }, [factoryLaunched, getDbBooks]);

  const fetchBooks = useCallback(async () => {
    try {
      const booksFS = (await ToolsAPI.getBooks()).books;
      const booksFreeze = (await BooksAPI.getBooksFreeze()).data;
      const books = booksFS.filter(
        (book) => !booksFreeze.some((freezeBook: DbBook) => freezeBook.id === book.name)
      );
      setListFreeze(books);
      if (!books.length) {
        NotificationManager.error('Could not fetch book list', 'API Error', notifTimeout);
        return;
      }

      setBooksSelection((prevBooksSelection) => {
        const newBooksSelection = [...prevBooksSelection];
        for (let i = 0; i < books.length; i++) {
          if (!newBooksSelection.find((book) => book.id === books[i].name)) {
            newBooksSelection.push({id: books[i].name, selected: false});
          }
        }
        return newBooksSelection;
      });
      setBooks(
        books.map((book: any) => {
          let newBook = undefined;
          const matchingDbBook = dbBooks.find((dbBook) => dbBook.id === book.name);
          if (!!matchingDbBook) {
            newBook = {
              ...book,
              isLocked: matchingDbBook.locked,
              version: matchingDbBook.major + '.' + matchingDbBook.minor,
            };
          }
          return newBook ?? book;
        })
      );
    } catch (e) {
      NotificationManager.error('Could not fetch book list', 'API Error', notifTimeout);
      console.error(`Could not fetch book list: ${e}`);
    }
  }, [dbBooks]);

  const fetchLogs = useCallback(async () => {
    try {
      const logs = (await ToolsAPI.getLogs()).logs;
      if (!logs.length) {
        NotificationManager.error('Could not fetch logs', 'API Error', notifTimeout);
        return;
      } else {
        setLastLogs(logs);
        return {logs: logs as string[]};
      }
    } catch (e) {
      // NotificationManager.error('Could not fetch logs', "API Error", notifTimeout)
      console.error(`Could not fetch logs list: ${e}`);
    }
  }, []);

  const fetchRunCount = async () => {
    try {
      const count = (await ToolsAPI.getCounts()).count;
      setRunCount(count);
      return count;
    } catch (e) {
      // NotificationManager.error('Could not fetch run count', "API Error", notifTimeout)
      console.error(`Could not fetch run count: ${e}`);
    }
  };

  const fetchErr = async () => {
    try {
      const errorLog = (await ToolsAPI.getErr()).err;
      setErr(errorLog ?? '');
    } catch (e) {
      // NotificationManager.error('Could not fetch err log', "API Error", notifTimeout)
      console.error(`Could not fetch err log: ${e}`);
    }
  };

  const launchFactory = async () => {
    // const url = factoryScope === 'ALL' ? 'start' : 'selection';
    let body = factoryScope === 'ALL' ? getAllBooksAsString() : getSelectionAsString();
    if (toolsStatus === 'Idle') {
      try {
        fetchRunCount();
        console.log(body);
        const res: any = await ToolsAPI.postSelection('selection', body);
        if (res.message!!) {
          hideConformationModal();
          setFactoryLaunched(true);
          NotificationManager.success('Started soundfactory', 'Success', notifTimeout);
        } else {
          NotificationManager.warning(
            'Soundfactory already started',
            'Invalid state',
            notifTimeout
          );
        }
      } catch (e) {
        NotificationManager.error('Could not start soundfactory', 'API Error', notifTimeout);
        console.error(`Could not start soundfactory : ${e}`);
      }
      fetchStatus();
    }
  };

  //////////////////////////////////////////////////////////////////
  // UI Events
  //////////////////////////////////////////////////////////////////

  const onButtonScope = async (scope: string) => {
    setFactoryScope(scope);
    showConfirmationModal();
  };

  const handleSelectionChange = (e: React.ChangeEvent<HTMLInputElement>, book: Book) => {
    setBooksSelection((prevBooksSelection) => {
      const bookIndex = prevBooksSelection.findIndex(
        (bookSelection) => bookSelection.id === book.name
      );
      if (bookIndex !== -1) {
        const newBooksSelection = [...prevBooksSelection];
        newBooksSelection[bookIndex].selected = e.target.checked;
        const selectedCount = newBooksSelection.filter((book) => book.selected).length;
        setSelectedCount(selectedCount);
        return newBooksSelection;
      }
      return prevBooksSelection;
    });
  };

  //////////////////////////////////////////////////////////////////
  // useEffect
  //////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (!notified) {
      setNotified(true);
      let query = new URLSearchParams(window.location.search);
      switch (query.get('message')) {
        case 'authSuccess':
          NotificationManager.success(
            'Succesfuly authentificated with Google',
            'Auth Success',
            notifTimeout
          );
          break;
        case 'authError':
          NotificationManager.error('Google auth unsuccessful', 'Auth Error', notifTimeout);
          break;
      }
      navigate('/MediaFactory');
    }
  }, [navigate, notified]);

  useEffect(() => {
    let fetchInterval = setInterval(async () => {
      fetchStatus();
      if (selectionCount() === 0) {
        if (toolsStatus === 'Idle') fetchBooks();
        if (toolsStatus === 'Processing' && runCount > 0) fetchLogs();
      }
    }, 2500);
    return () => clearInterval(fetchInterval);
  }, [fetchStatus, selectedCount, selectionCount, fetchBooks, fetchLogs, runCount, toolsStatus]);

  useEffect(() => {
    fetchBooks();
    fetchStatus();
    fetchRunCount();
    fetchErr();
    if (runCount > 0) fetchLogs();
  }, [runCount, fetchBooks, fetchStatus, fetchLogs]);

  useEffect(() => {
    async function notifyOnFinish() {
      const count = await fetchRunCount();
      if (count && count < 1) return;
      const {logs} = (await fetchLogs()) ?? {};
      if (!!logs && !!prevStatus && toolsStatus === 'Idle') {
        if (logs.filter((row) => row.includes(logEndSequence)).length > 0)
          NotificationManager.success('Sound factory succesfully completed', 'Success', 2500);
        else NotificationManager.error('Something went wrong during last run', 'TOOLS Error', 2500);
      }
    }
    notifyOnFinish();
  }, [toolsStatus, prevStatus, fetchLogs]);

  useEffect(() => {
    fetchRunCount();
  }, [toolsStatus]);

  return (
    <>
      <header>
        <img
          src={homeLogo}
          onClick={() => navigate('/')}
          className="link-logo"
          alt="home_logo"
          style={{height: '50px', width: '50px', marginTop: '50px'}}
        />
      </header>
      <Modal handleClose={hideConformationModal} show={showConfirmation}>
        <div
          style={{
            backgroundColor: '#252931',
            minHeight: '20vh',
            paddingTop: '5px',
            flexDirection: 'column',
          }}>
          <h2 style={{color: 'yellow'}}>Are you sure you want to start soundfactory ?</h2>
          <h3> -LOCKED books will be skipped if you do</h3>
          <h3> -Make sure that all unrecognized books are NEW books (no typos in titles)</h3>
          <div>
            <div style={{maxWidth: '100px', margin: '30px auto'}}>
              <SquareButton useButton={launchFactory} disabled={false} text={'YES'} isBig={true} />
            </div>
          </div>
        </div>
      </Modal>
      <div className="Container">
        <h1>Media Factory 🎶 🖼️ ⚙</h1>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            minWidth: '66%',
            maxWidth: '100%',
          }}>
          <div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
            {!books || !books.length || books.length < 1 ? (
              <div style={{alignSelf: 'center'}}>
                <h2 style={{color: 'salmon', alignSelf: 'center'}}>No books found</h2>
                <h2 style={{margin: '0px 0px 10px 0px', alignSelf: 'center'}}>
                  Add books through FTP
                </h2>
              </div>
            ) : (
              <div style={{flex: 1, alignSelf: 'center'}}>
                <h2
                  style={{
                    margin: 0,
                    padding: 0,
                    color: books.every((book) => book.isLocked === false)
                      ? 'lightgreen'
                      : books.every((book) => !!book.isLocked)
                      ? 'salmon'
                      : 'orange',
                  }}>
                  {books.length} {books.length > 1 ? 'books' : 'book'} found on the FTP :
                </h2>
                <ul style={{overflow: 'auto', height: '800px'}}>
                  {books.map((book, index) => (
                    <li key={index}>
                      {!book.isLocked && (
                        <input type="checkbox" onChange={(e) => handleSelectionChange(e, book)} />
                      )}
                      {!!book.isLocked ? '🔒' : book.isLocked === false ? '✔️ ' : '⚠️'}
                      <strong> {book.name} </strong>
                      <small>
                        {' v'}
                        {book.version}{' '}
                      </small>
                      ({book.fileCount} {book.fileCount === 1 ? 'file' : 'files'}
                      {!!book.isLocked
                        ? ', LOCKED'
                        : book.isLocked === false
                        ? ''
                        : ', NO MATCH IN DB'}
                      ) {/* '=== false' necessary to avoid overlap with non-existence test*/}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {toolsStatus !== 'Idle' && (
              <div
                style={{
                  display: 'flex',
                  alignSelf: 'center',
                  justifyContent: 'center',
                }}>
                {' '}
                <CoolArrows />{' '}
                <h3 style={{alignSelf: 'center', margin: 0}}>
                  {' '}
                  Ongoing : run № {(toolsStatus === 'Starting' ? runCount + 1 : runCount) ??
                    '???'}{' '}
                </h3>
              </div>
            )}
            {!(!books || !books.length || books.length < 1) && (
              <div className="StatusContainer">
                <ColoredDot status={toolsStatus} />
                <h3>Status : {toolsStatus}</h3>
              </div>
            )}
          </div>
          {!!lastLogs && toolsStatus !== 'Idle' && toolsStatus !== 'Starting' && (
            <Logs logs={lastLogs} />
          )}
        </div>
        <div
          className="d-flex flex-row"
          style={{
            display: 'flex',
          }}>
          <div className="p-2">
            <SquareButton
              isBig={true}
              useButton={() => onButtonScope('ALL')}
              disabled={
                toolsStatus !== 'Idle' ||
                !books ||
                !books.length ||
                books.length < 1 ||
                books.every((book) => !!book.isLocked)
              }
              text={toolsStatus === 'Idle' ? 'All Books' : 'WORKING...'}
            />
          </div>
          <div className="p-2">
            <SquareButton
              isBig={true}
              useButton={() => onButtonScope('SELECTION')}
              disabled={
                toolsStatus !== 'Idle' ||
                !books ||
                !books.length ||
                books.length < 1 ||
                books.every((book) => !!book.isLocked) ||
                selectedCount === 0
              }
              text={toolsStatus === 'Idle' ? selectedCount + ' selected' : 'WORKING...'}
            />
          </div>
        </div>
        {toolsStatus === 'Idle' && (
          <>
            {runCount !== 0 ? (
              <RunRecap {...runRecapProps} />
            ) : (
              <h3 style={{color: 'gray'}}>No past runs recorded</h3>
            )}
          </>
        )}
        <Link to={'/RunList'} style={{textDecoration: 'none'}}>
          <h3 style={{color: 'white', borderBottom: '1px solid white'}}>See all runs</h3>
        </Link>
        {/* <div className="p-2">
          <SquareButton
            isBig={false}
            // style={{padding: '5px'}}
            useButton={() => onResetCache()}
            disabled={toolsStatus !== 'Idle'}
            text={'Reset cache'}
          />
        </div> */}
      </div>
    </>
  );
}

export default withProtected(DbBooks(MediaFactory));
