import React, {createContext, useContext, useEffect, useState} from 'react';
import type {User} from './types';
import {NotificationManager} from 'react-notifications';
import GoogleAPI from '../../api/GoogleAPI';
import Env from '../../env';

interface UserProps {
  user: User | null;
  authUrl: string;
  logIn: (code: string) => Promise<User | null>;
  checkAuthStatus: () => Promise<void>;
}

const UserContext = createContext<UserProps>({
  user: null,
  authUrl: '',
  logIn: async () => null,
  checkAuthStatus: async () => {},
});
export const UserContextProvider: React.FC = ({children}) => {
  const [user, setUser] = useState<User | null>(null);
  const [authUrl, setAuthUrl] = useState('');

  useEffect(() => {
    const fetchUrl = async () => {
      const url = await getUrl();
      setAuthUrl(url);
    };
    fetchUrl();
  }, []);

  const getUrl = async () => {
    try {
      const urlResp = await GoogleAPI.redirect(window.location.href);
      if (urlResp.status === 'done') {
        const url = urlResp.data.replace('%25HOST%25', window.location.origin);
        setAuthUrl(url);
        return url;
      } else {
        setAuthUrl('');
        return null;
      }
    } catch (ex) {
      console.error(ex);
      setAuthUrl('');
      return null;
    }
  };

  const checkAuthStatus = async () => {
    try {
      const userResp = await fetch(`${Env.API_URL}/google/user`, {
        credentials: 'include',
      }).then((res) => res.json());
      if (userResp.status === 'done') {
        setUser(userResp.data);
      } else {
        setUser(null);
        if (authUrl) {
          window.location.href = authUrl;
        }
      }
    } catch (e) {
      setUser(null);
    }
  };

  const logIn = async (code: string) => {
    try {
      const userResp = await fetch(`${Env.API_URL}/google/user?code=${code}`, {
        credentials: 'include',
      }).then((res) => res.json());
      if (userResp.status === 'done') {
        setUser(userResp.data);
        return userResp.data;
      } else {
        console.error('Could not log user in:', userResp);
        return null;
      }
    } catch (ex) {
      console.error('Could not log user in:', ex);
      return null;
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        authUrl,
        logIn,
        checkAuthStatus,
      }}>
      {children}
    </UserContext.Provider>
  );
};

export function useUser() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('Context must be used within a provider');
  }
  return context;
}

export const withProtected = (WrappedComponent: React.ComponentType) => {
  const ProtectedComponent: React.FC = () => {
    const {checkAuthStatus} = useUser();
    useEffect(() => {
      checkAuthStatus();
    }, []);
    return <WrappedComponent />;
  };
  return ProtectedComponent;
};
