import React, {useState} from 'react';
import '../css/components/Modal.css';
import ColoredDot from '../components/ColoredDot';

type ModalProps = {
  handleClose: () => void;
  show: boolean;
  children: React.PropsWithChildren<any>;
};

function Modal({handleClose, show, children}: ModalProps) {
  const [hovered, setHovered] = useState(false);
  const mouseEntered = () => setHovered(true);
  const mouseLeft = () => setHovered(false);
  const showHideClassName = show ? 'modal display-block' : 'modal display-none';

  return (
    <div className={showHideClassName} onClick={hovered ? () => {} : handleClose}>
      <section className="modal-main" onMouseEnter={mouseEntered} onMouseLeave={mouseLeft}>
        <div style={{marginTop: '4px', float: 'right'}}>
          <ColoredDot status="CloseButton" onClick={handleClose} />
        </div>
        {children}
      </section>
    </div>
  );
}

export default Modal;
