import {api} from './axiosConfig';

interface ToolsApi {
  getStatus: () => Promise<APIResponse>;
  getBooks: () => Promise<APIToolsBook>;
  getErr: () => Promise<APIErr>;
  resetCache: () => Promise<APIResponse>;
  getLogs: () => Promise<APILogs>;
  getCounts: () => Promise<APICount>;
  postSelection: (url: string, data: undefined | string) => Promise<APIResponse>;
  fetchRuns: () => Promise<APIResponse>;
  purgeLogs: () => Promise<APIResponse>;
}

const URL_TOOLS = '/tools';

const ToolsAPI: ToolsApi = {
  getStatus: async () => {
    const response = await api.request({
      url: `${URL_TOOLS}/status`,
      method: 'GET',
    });
    return response.data;
  },

  getBooks: async () => {
    const response = await api.request({
      url: `${URL_TOOLS}/books`,
      method: 'GET',
    });
    return response.data;
  },

  getErr: async () => {
    const response = await api.request({
      url: `${URL_TOOLS}/err`,
      method: 'GET',
    });
    return response.data;
  },

  resetCache: async () => {
    const response = await api.request({
      url: `${URL_TOOLS}/reset/cache`,
      method: 'GET',
    });
    return response.data;
  },

  getLogs: async () => {
    const response = await api.request({
      url: `${URL_TOOLS}/logs`,
      method: 'GET',
    });
    return response.data;
  },

  getCounts: async () => {
    const response = await api.request({
      url: `${URL_TOOLS}/runcount`,
      method: 'GET',
    });
    return response.data;
  },

  postSelection: async (url: string, data: undefined | string) => {
    const response = await api.request({
      url: `${URL_TOOLS}/${url}`,
      method: 'POST',
      headers: {'Content-Type': 'text/plain'},
      data,
    });
    return response.data;
  },

  fetchRuns: async () => {
    const response = await api.request({
      url: `${URL_TOOLS}/runs`,
      method: 'GET',
    });
    return response.data;
  },

  /// POST ??
  purgeLogs: async () => {
    const response = await api.request({
      url: `${URL_TOOLS}/purge`,
      method: 'POST',
    });
    return response.data;
  },
};

export default ToolsAPI;
