import {useCallback, useEffect, useState} from 'react';
import BooksAPI from '../api/BooksAPI';
import {NotificationManager} from 'react-notifications';

const withDbBooks = (WrappedComponent: React.FC<DbBooksProps>) => {
  const Wrapped: React.FC = () => {
    const [dbBooks, setDbBooks] = useState([]);

    const getDbBooks = useCallback(async () => {
      let dbBooksRes = null;
      try {
        dbBooksRes = (await BooksAPI.getBooks()).data;
        const books = dbBooksRes.filter((book: DbBook) => !book.freeze).map((book: DbBook) => book);
        setDbBooks(books);
      } catch (e) {
        console.dir(e);
        if (!dbBooksRes) NotificationManager.error('Failed to fetch database books', 'Error', 10);
      }
    }, []);

    useEffect(() => {
      getDbBooks();
    }, [getDbBooks]);
    return <WrappedComponent dbBooks={dbBooks} getDbBooks={getDbBooks} />;
  };
  return Wrapped;
};

export default withDbBooks;
