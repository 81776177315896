import styles from '../../css/styles';
import {useParams} from 'react-router-dom';
import {useEffect, useMemo, useRef, useState, VFC} from 'react';
import {bfetch, fetchWithRange} from '../../utils/fetch';
import {getHours, getMilliseconds, getMinutes, getSeconds} from 'date-fns';

export const LiveLog: VFC = () => {
  const {filename = ''} = useParams<{filename: string}>();

  const [content, setContent] = useState('');
  const [isError, setIsError] = useState(false);
  const readBytes = useRef(0);

  const poll = async () => {
    try {
      // Read how much bytes are available to read
      const response = await bfetch(`/livelogs/${filename}`, {method: 'GET'});
      const body = await response.text();

      const availableBytes = body.length;

      if (!availableBytes) return;

      // Return if no new bytes are available
      if (availableBytes <= readBytes.current) return;

      const data = await fetchWithRange({
        method: 'GET',
        path: `/livelogs/${filename}`,
        rangeStart: readBytes.current,
        rangeEnd: availableBytes,
      });

      if (data.status !== 200 && data.status !== 206) {
        const error = await data.text();
        console.error(error);
        return;
      }

      const array = await data.clone().arrayBuffer();
      readBytes.current += array.byteLength;

      const text = await data.text();

      setContent((p) => {
        return p + text;
      });
    } catch (e) {
      console.error(e);
      setIsError(true);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      poll();
    }, 300);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const contentWithSyntaxHighlighting = useMemo(() => {
    const lines = content.split('\n');

    const linesWithSyntax = lines.map((line) => {
      const datePos = line.indexOf(':');
      const severityPos = line.indexOf(':', datePos + 1);
      const modulePos = line.indexOf(':', severityPos + 1);

      if (datePos === -1 || severityPos === -1 || modulePos === -1) return line;

      let date = line.slice(0, datePos);
      const severity = line.slice(datePos + 1, severityPos);
      const module = line.slice(severityPos + 1, modulePos);
      const content = line.slice(modulePos + 1);

      const dateNumber = parseInt(date, 10);
      if (typeof dateNumber === 'number' && isFinite(dateNumber)) {
        const now = new Date(dateNumber);

        date = `${getHours(now)}h:${getMinutes(now)}m:${getSeconds(now)}s:${getMilliseconds(
          now
        )}ms`;
      }

      return `<span style="color: #00E0E0;">${date}</span>:<span style="color: #F4AA36;">${severity}</span>:<span style="color: #ACE337;">${module}</span>:<span style="color: #A9B7C5;">${content}</span>`;
    });

    return linesWithSyntax.join('\n');
  }, [content]);

  return (
    <div
      className="w-screen h-screen p-4 flex flex-col"
      style={{backgroundColor: styles.colors.background}}>
      <p className="text-7xl font-bold text-center text-white">Logs</p>

      <p className="text-white mb-10">
        Currently viewing Navi <span className="font-bold">{filename.replace('.log', '')}</span>
        {isError ? (
          <>
            <br />
            An error occurred.
          </>
        ) : null}
      </p>

      <div
        className="p-2 grow overflow-scroll flex flex-col-reverse"
        style={{backgroundColor: 'black'}}>
        <p
          className="text-white text-sm whitespace-pre-wrap font-mono"
          dangerouslySetInnerHTML={{__html: contentWithSyntaxHighlighting}}></p>
      </div>
    </div>
  );
};
