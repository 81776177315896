import {useUser} from '../context/user';
import React, {useEffect} from 'react';

const AuthRedirect: React.FC = () => {
  const {logIn} = useUser();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get('code');

    const waitForLogin = async (code: string) => {
      let usr = await logIn(code);
      if (!usr) window.location.href = '/MediaFactory?message=authSuccess';
      else window.location.href = '/?message=authError';
    };

    if (!code) {
      console.error('Authentication failed: Google did not provide us with a code!');
      window.location.href = '/?message=authError';
    } else {
      waitForLogin(code);
    }
  }, []);

  return <p>Redirecting..</p>;
};

export default AuthRedirect;
