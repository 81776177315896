import React, {useState} from 'react';
import {Statuses} from '../modules/Statuses';
import SquareButton from './SquareButton';
import Modal from './Modal';
import Logs from './Logs';
import {NotificationManager} from 'react-notifications';

type RunRecapProps = {
  message: string;
  error: string;
  logs: string[];
};

function RunRecap({message, logs, error}: RunRecapProps) {
  const status = error === '' ? 'Success' : 'Error';
  const [show, setShow] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const showModal = (content: string) => {
    setModalContent(content);
    setShow(true);
  };
  const hideModal = () => {
    setShow(false);
  };
  const copyErr = () => {
    navigator.clipboard.writeText(error);
    NotificationManager.info('Text copied to clipboard', 'Info', 1500);
  };
  const [TextHovered, setTextHovered] = useState(false);
  const mouseEnteredText = () => setTextHovered(true);
  const mouseLeftText = () => setTextHovered(false);
  const textStyle = {
    overflow: 'scroll',
    maxHeight: '70vh',
    whiteSpace: 'pre' as 'pre',
    color: 'white',
    borderLeft: '1px solid gray',
    borderTop: '1px solid gray',
    lineHeight: '1em',
    paddingLeft: '10px',
    marginLeft: '15px',
    paddingTop: '10px',
    paddingBottom: '10px',
    cursor: TextHovered ? 'pointer' : 'default',
  };

  return (
    <>
      <Modal show={show} handleClose={hideModal}>
        {modalContent === 'logs' ? (
          <Logs logs={logs} />
        ) : modalContent === 'err' ? (
          <div style={{backgroundColor: '#252931', paddingLeft: '10px'}}>
            <div style={{fontWeight: 'bold', fontSize: '1.3em', color: 'white'}}>Error :</div>
            <p
              onClick={copyErr}
              style={textStyle}
              onMouseEnter={mouseEnteredText}
              onMouseLeave={mouseLeftText}>
              {error}
            </p>
          </div>
        ) : (
          <></>
        )}
      </Modal>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <h3 style={{marginRight: '10px', color: 'lightgray'}}>{message}, </h3>
        <h3 style={{color: `${Statuses[status].color}`}}>{status}</h3>
        <div style={{height: '25px', width: '65px', marginLeft: '10px'}}>
          <SquareButton useButton={() => showModal('logs')} text="Logs" disabled={false} />
        </div>
        {error !== '' && (
          <div style={{height: '25px', width: '65px', marginLeft: '10px'}}>
            <SquareButton useButton={() => showModal('err')} text="Error" disabled={false} />
          </div>
        )}
      </div>
    </>
  );
}

export default RunRecap;
