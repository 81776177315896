/**
 * Use this function with react-query.
 */
export async function query<T = unknown>(path: string): Promise<T> {
  const response = await fetch(`${process.env.REACT_APP_API_URL}${path}`, {credentials: 'include'});

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response.json();
}
