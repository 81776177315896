import Env from '../env';
import axios from 'axios';
// import * as Sentry from '@sentry/react';

export const api = axios.create({
  baseURL: `${Env.API_URL}`,
  withCredentials: true,
});

const errorHandler = (error: any) => {
  const statusCode = error.response?.status;

  if (error.code === 'TO_DEFINE') {
    return Promise.resolve();
  }

  if (statusCode && statusCode !== 401) {
    console.error(error);
    // Sentry ??
    // Sentry.captureException(error);
  }

  return Promise.reject(error);
};

api.interceptors.response.use(undefined, (error) => {
  return errorHandler(error);
});
