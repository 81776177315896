import React, {useState} from 'react';
import {Statuses} from '../modules/Statuses';

type ColoredDotProps = {
  status: string;
  onClick?: () => void;
};

function ColoredDot({status, onClick}: ColoredDotProps) {
  const settings = (Statuses as any)[status];
  const isButton = !!settings.isButton;
  const [hovered, setHovered] = useState(false);
  const mouseEntered = isButton
    ? () => {
        setHovered(true);
      }
    : () => {};
  const mouseLeft = isButton
    ? () => {
        setHovered(false);
      }
    : () => {};
  const dotStyle = {
    borderRadius: '50%',
    height: '25px',
    width: '25px',
    marginRight: '10px',
    // border: isButton ? '1px solid black' : 'none',
    textAlign: 'center' as 'center',
    color: 'white',
    backgroundColor: settings.color ?? Statuses.default.color,
    cursor: hovered ? 'pointer' : 'default',
  };
  return (
    <div style={dotStyle} onClick={onClick} onMouseEnter={mouseEntered} onMouseLeave={mouseLeft}>
      {settings.text ?? ''}
    </div>
  );
}

export default ColoredDot;
