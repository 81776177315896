import React, {useEffect, useState} from 'react';
import Logo from '../assets/logo';
import grafanaLogo from '../assets/grafanaLogo.png';
import matomoLogo from '../assets/matomoLogo.png';
import sounfFileLogo from '../assets/soundFileLogo.png';
import 'react-notifications/lib/notifications.css';
import '../css/pages/Home.css';
import {NotificationManager} from 'react-notifications';
import {Link, useNavigate} from 'react-router-dom';
import {withProtected} from '../context/user';

function Home() {
  const navigate = useNavigate();
  const notifTimeout = 1500;
  const [notified, setNotified] = useState(false);
  useEffect(() => {
    if (!notified) {
      setNotified(true);
      let query = new URLSearchParams(window.location.search);
      switch (query.get('message')) {
        case 'authSuccess':
          NotificationManager.success(
            'Succesfuly authentificated with Google',
            'Success',
            notifTimeout
          );
          break;
        case 'authError':
          NotificationManager.error('Google auth unsuccessful', 'Error', notifTimeout);
          break;
      }
      navigate('/');
    }
  }, [notified, navigate]);
  return (
    <div className="Home">
      <header className="Home-header">
        <div
          className="flex mb-4 bg-slate-300 text-center justify-center items-center content-center rounded-lg"
          style={{minWidth: '400px'}}>
          <Logo height={120} style={{marginBottom: '40px'}} />
        </div>
        <a href="https://analytics.bugali.com/monitoring" className="link-box">
          <img src={grafanaLogo} className="link-logo" alt="grafana_logo" />
          <p className="link-text">GRAFANA Reports</p>
        </a>

        <a href="https://matomo.bugali.io" className="link-box">
          <img src={matomoLogo} className="link-logo" alt="matomo_logo" />
          <p className="link-text">MATOMO Analytics</p>
        </a>

        <Link to="/MediaFactory" className="link-box">
          <img src={sounfFileLogo} className="link-logo" alt="sound_file_logo" />
          <p className="link-text">MEDIA Factory</p>
        </Link>

        <Link to="/live-logs" className="link-box">
          <p className="link-logo">📝</p>
          <p className="link-text">LIVE Logs</p>
        </Link>
      </header>
    </div>
  );
}

export default withProtected(Home);
