import {api} from './axiosConfig';
import Env from '../env';

interface GoogleApi {
  redirect: (url: string) => Promise<APIResponse>;
  isAuth: () => Promise<APIResponse>;
  login: (code: string) => Promise<APIResponse>;
}

const URL_GOOGLE = '/google';

const GoogleAPI: GoogleApi = {
  redirect: async (url: string) => {
    const response = await api.request({
      baseURL: Env.PROD_URL,
      url: `${URL_GOOGLE}/url?redirect_url=${url}`,
      method: 'GET',
    });
    return response.data;
  },


  isAuth: async () => {
    const response = await api.request({
      baseURL: Env.PROD_URL,
      url: `${URL_GOOGLE}/user`,
      method: 'GET',
    });
    return response.data;
  },

  login: async (code: string) => {
    const response = await api.request({
      url: `${URL_GOOGLE}/user?code=${code}`,
      method: 'GET',
    });
    return response.data;
  },
};


export default GoogleAPI;
