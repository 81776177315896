/**
 * This is a wrapper around `fetch` with proper configuration to Bugali API.
 *
 * @param path What URL to fetch, relative to the API. Ex: `/livelogs` will fetch `api.bugali.com/livelogs`
 */
export function bfetch(path: string, init?: RequestInit): Promise<Response> {
  if (path.startsWith('/')) path = path.slice(1);

  return fetch(`${process.env.REACT_APP_API_URL}/${path}`, {credentials: 'include', ...init});
}

export type FetchWithRangeOptions = {
  /**
   * Relative to the API, what URL to fetch.
   */
  path: string;

  method: 'GET';

  /**
   * From which byte should we read the file?
   */
  rangeStart: number;

  /**
   * Up until wich byte should we read the file?
   */
  rangeEnd: number;
};

/**
 * Fetch a file with `Range` HTTP header.
 */
export function fetchWithRange({
  method,
  path,
  rangeEnd,
  rangeStart,
}: FetchWithRangeOptions): Promise<Response> {
  if (rangeStart > rangeEnd || rangeStart < 0 || rangeEnd < 0) {
    throw new Error('invalid range');
  }

  if (path.startsWith('/')) path = path.slice(1);

  return fetch(`${process.env.REACT_APP_API_URL}/${path}`, {
    headers: {Range: `bytes=${rangeStart}-${rangeEnd}`},
    credentials: 'include',
    method: method,
  });
}
