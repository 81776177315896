import React from 'react';
import '../css/components/CoolArrows.css';

function CoolArrows() {
  return (
    <div className="center-con">
      <div className="sub-con">
        <span className="arrow first next "></span>
        <span className="arrow second next "></span>
        <span className="arrow third next "></span>
      </div>
    </div>
  );
}

export default CoolArrows;
