import React from 'react';
import {useNavigate} from 'react-router-dom';
import homeLogo from '../assets/homeLogo.png';
import sadKoro from '../assets/sadKoro.png';

function NotFound() {
  const navigate = useNavigate();
  return (
    <>
      <header style={{height: '10vh'}}>
        <img
          src={homeLogo}
          onClick={() => navigate('/MediaFactory')}
          className="link-logo"
          alt="return_logo"
          style={{height: '50px', width: '50px', marginTop: '50px'}}
        />
      </header>
      <div style={{backgroundColor: '#252931', height: '90vh', display: 'block'}}>
        <h1
          style={{
            textAlign: 'center',
            verticalAlign: 'center',
            position: 'absolute',
            top: '30%',
            left: '50%',
            transform: 'translate(-50%)',
          }}>
          404 NOT FOUND
        </h1>
        <img
          src={sadKoro}
          alt={sadKoro}
          style={{
            height: '100px',
            margin: 'auto',
            position: 'absolute',
            top: '45%',
            left: '50%',
            transform: 'translate(-50%)',
          }}
        />
      </div>
    </>
  );
}

export default NotFound;
