import { useQuery } from '@tanstack/react-query';
import { ChangeEventHandler, useEffect, useMemo, useState, VFC } from 'react';
import { query } from '../../utils/query';
import Fuse from 'fuse.js';
import styles from '../../css/styles';
import { Link } from 'react-router-dom';
import { formatDistance } from 'date-fns';
import { withProtected } from '../../context/user';

const LiveLogsList: VFC = () => {

  const { data, error, isLoading } = useQuery(['liveLogsList'], () =>
    query<{ files: { filename: string; updatedAt: string }[] }>('/livelogs/')
  );

  const [search, setSearch] = useState('');
  const onSearch: ChangeEventHandler<HTMLInputElement> = (event) => {
    setSearch(event.target.value);
  };
  useEffect(() => {
    console.log('La liste des logs a été actualisé !');
  });


  // If the search bar have a value, search in the list of files
  const files = useMemo(() => {
    const fuse = new Fuse(data?.files ?? [], { keys: ['filename'] });
    if (!search) return data?.files ?? [];

    const result = fuse.search(search);

    return result.map((r) => r.item);
  }, [search, data]);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error || !data) {
    return <p>Error</p>;
  }

  const filesJSX = files.map(({ filename, updatedAt: _updatedAt }) => {
    const title = filename.replace('.log', '');
    const updatedAt = new Date(_updatedAt);

    return (
      <Link key={filename} to={`/live-logs/${filename}`}>
        <p className="underline" style={{ color: styles.colors.link }}>
          <span className="font-bold">{title}</span> (last updated{' '}
          {formatDistance(updatedAt, new Date(), { addSuffix: true })})
        </p>
      </Link>
    );
  });

  return (
    <div className="w-screen h-screen p-4" style={{ backgroundColor: styles.colors.background }}>
      <p className="text-7xl font-bold text-center text-white">Logs</p>

      <p className="text-white mb-5">Select a Navi to read its logs live.</p>

      <input className="mb-5" type="text" placeholder="Search..." onChange={onSearch} />

      {filesJSX}
    </div>
  );
};
export default withProtected(LiveLogsList) 
