import React from 'react';
import '../css/components/SquareButton.css';

type SquareButtonProps = {
  useButton: () => void;
  disabled: boolean;
  text: string;
  isBig?: boolean;
};

function SquareButton({useButton, disabled, text, isBig = false}: SquareButtonProps) {
  const textStyle = {
    color: '#252931',
    fontWeight: 'bold',
    fontSize: isBig ? '2em' : '1.3em',
  };
  return (
    <button className="Button" onClick={useButton} disabled={disabled}>
      <div style={textStyle}>{text}</div>
    </button>
  );
}

export default SquareButton;
