import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import MediaFactory from './pages/MediaFactory';
import AuthRedirect from './pages/AuthRedirect';
import RunList from './pages/RunList';
import NotFound from './pages/NotFound';
import LiveLogsList from './pages/LiveLogs/LiveLogsList';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { LiveLog } from './pages/LiveLogs/LiveLog';

const queryClient = new QueryClient();

// Test 

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/auth_redirect" element={<AuthRedirect />} />
        <Route path="/MediaFactory" element={<MediaFactory />} />
        <Route path="/RunList" element={<RunList />} />

        <Route path="/live-logs" element={<LiveLogsList />} />
        <Route path="/live-logs/:filename" element={<LiveLog />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </QueryClientProvider>
  );
}

export default App;
