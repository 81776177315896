import * as React from 'react';

interface Props {
  height: number;
  color?: string;
}

function SvgComponent(props: React.SVGProps<SVGSVGElement> & Props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={(31 * props.height) / 42.15}
      height={props.height}
      viewBox="0 0 31 42.15"
      style={{animation: 'Home-logo-spin', margin: '20px'}}>
      <path
        d="M.003.01l.003 29.234c3.377 17.21 27.847 17.215 30.873 0 .936-8.409-5.592-15.677-11.428-16.581.949-7.79-4.059-11.651-8.388-12.608zm22.963 3.972c.037 5.085 7.244 4.506 7.243"
        fill={props.color || '#02c'}
      />
      <circle
        cx="25"
        cy="5"
        r="3.5"
        fill="#02c"
        style={{animation: 'fallAndFade 4s infinite', animationDelay: '1s'}}
      />
    </svg>
  );
}

export default SvgComponent;
