import React from 'react';
type logProps = {
  logs: string[];
};

function Logs({logs}: logProps) {
  return (
    <div
      style={{
        flex: 1,
        borderLeft: '1px solid white',
        borderRight: '1px solid white',
        width: '100%',
        maxHeight: '66vh',
        backgroundColor: '#252931',
        overflow: 'auto',
      }}>
      <h2 style={{margin: '0 15px'}}>LOGS :</h2>
      <ul style={{border: 'none'}}>
        {logs.map((line, index) => (
          <li key={index} style={{color: 'lightgray', margin: '0 '}}>
            {line}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Logs;
